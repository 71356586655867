import {
    apiClient
} from '@/services/config.js';



export default {
    fetchAllSwaps() {
        return apiClient.get(
            "/api/shift-swaps/all");
    },
    publishSwap(shiftId, shiftDate, contact, preferences) {
        return apiClient.post("/api/shift-swaps", {
            status: "Open",
            shiftRegistrationId: shiftId,
            shiftDate: shiftDate,
            contact: contact,
            preferedShifts: preferences,
        });
    },
    fetchUserSwaps() {
        return apiClient.get(
            "/api/shift-swaps/by-me");
    },
    fetchSwap(swapId) {
        return apiClient.get("/api/shift-swaps/" + swapId)
    },
    editSwap(swapId, shiftId, shiftDate, contact, preferences) {
        return apiClient.put("/api/shift-swaps/" + swapId, {
            status: "Open",
            shiftRegistrationId: shiftId,
            shiftDate: shiftDate,
            contact: contact,
            preferedShifts: preferences,
        });
    },
    deleteSwap(swapId) {
        return apiClient.delete(
            "/api/shift-swaps/" + swapId);
    },
    toggleFavorite(swapId) {
        return apiClient.post("/api/shift-swaps/toggle-favorite", {
            shiftSwapId: swapId
        });
    },
    fetchFavoriteSwaps() {
        return apiClient.get("/api/shift-swaps/my-favorites");
    },
    fetchContact(swapId) {
        return apiClient.get("/api/shift-swaps/contact/" + swapId);
    }
};
