<template>
  <div class="grid">
    <div class="days" v-for="day in days" v-bind:key="day">
      <div class="day">
        <div class="label">{{ day }}</div>
         <div v-if="day === 'Lun.'" class="line">
          <div class="item disabled"></div>
          <b-checkbox-button native-value="Lun. - 09:00" type="is-dark" v-model="selectedPreferences" @input="updateParentPreferences">9h</b-checkbox-button>
          <div class="item disabled"></div>
          <b-checkbox-button native-value="Lun. - 13:00" type="is-dark" v-model="selectedPreferences" @input="updateParentPreferences">13h</b-checkbox-button>
          <div class="item disabled"></div>
          <div class="item disabled"></div>

        </div>
        <div v-else-if="day === 'Dim.'" class="line">
          <div class="item disabled"></div>
          <b-checkbox-button native-value="Dim. - 08:30" type="is-dark" v-model="selectedPreferences" @input="updateParentPreferences">8h30</b-checkbox-button>
          <b-checkbox-button native-value="Dim. - 11:15" type="is-dark" v-model="selectedPreferences" @input="updateParentPreferences">11h15</b-checkbox-button>
          <div class="item disabled"></div>
          <div class="item disabled"></div>
          <div class="item disabled"></div>

        </div>
        <div v-else class="line">
          <b-checkbox-button :native-value="day + ' - 06:00'" type="is-dark" v-model="selectedPreferences" @input="updateParentPreferences" class="item">6h</b-checkbox-button>
          <b-checkbox-button :native-value="day + ' - 08:00'" type="is-dark" v-model="selectedPreferences" @input="updateParentPreferences" class="item">8h</b-checkbox-button>
          <b-checkbox-button :native-value="day + ' - 10:45'" type="is-dark" v-model="selectedPreferences" @input="updateParentPreferences" class="item">10h45</b-checkbox-button>
          <b-checkbox-button :native-value="day + ' - 13:30'" type="is-dark" v-model="selectedPreferences" @input="updateParentPreferences" class="item">13h30</b-checkbox-button>
          <b-checkbox-button :native-value="day + ' - 16:15'" type="is-dark" v-model="selectedPreferences" @input="updateParentPreferences" class="item">16h15</b-checkbox-button>
          <b-checkbox-button :native-value="day + ' - 19:00'" type="is-dark" v-model="selectedPreferences" @input="updateParentPreferences" class="item">19h</b-checkbox-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GridWeekSelect",
  props: {
    preferences: {
      type: Array,
      required: true
    }
  },
  watch: {
    preferences(updatedPreferences) {
      this.selectedPreferences = updatedPreferences
    }
  },
  data: function() {
    return {
      days: ["Lun.", "Mar.", "Mer.", "Jeu.", "Ven.", "Sam.", "Dim."],
      selectedPreferences: []
    }
  },
  methods: {
    updateParentPreferences() {
      this.$emit('updatePreferences', this.selectedPreferences);
    },
  },
};
</script>

<style lang="css" scoped>
.grid {
  margin-bottom: 20px;
}
.day {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.label {
  margin-right: 30px;
}
.days {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.line {
  display: flex;
  justify-content: space-between;
}
.line > * { width: 65px; }
.disabled {
  background-color:  #f2f4f4;
  border-radius: 4px;
}
</style>
