
export default {
    methods: {
        formatDate : function (datein) {
            let newDate = new Date(datein);
            let dayNames = [
              "Dimanche",
              "Lundi",
              "Mardi",
              "Mercredi",
              "Jeudi",
              "Vendredi",
              "Samedi"
            ];

            let monthNames = [
              "janvier",
              "février",
              "mars",
              "avril",
              "mai",
              "juin",
              "juillet",
              "août",
              "septembre",
              "octobre",
              "novembre",
              "décembre"
            ];

            let dayName = newDate.getDay();
            let day = newDate.getDate();
            let monthIndex = newDate.getMonth();
            let year = newDate.getFullYear();
            let heures = (newDate.getHours() + 100).toString().substring(1);
            let minutes = (newDate.getMinutes() + 100).toString().substring(1);

            return (
              dayNames[dayName] +
              " " +
              day +
              " " +
              monthNames[monthIndex] +
              " " +
              year +
              " - " +
              heures +
              "h" +
              minutes
            );
          },
          formatDateOnly : function (datein) {
            let date = new Date(datein);
            let newDate = new Date(
              date.getTime() + date.getTimezoneOffset() * 60 * 1000
            );
            let offset = date.getTimezoneOffset() / 60;
            let hours = date.getHours();

            newDate.setHours(hours - offset);

            let dayNames = [
              "Dimanche",
              "Lundi",
              "Mardi",
              "Mercredi",
              "Jeudi",
              "Vendredi",
              "Samedi"
            ];

            let monthNames = [
              "janvier",
              "février",
              "mars",
              "avril",
              "mai",
              "juin",
              "juillet",
              "août",
              "septembre",
              "octobre",
              "novembre",
              "décembre"
            ];

            let dayName = newDate.getDay();
            let day = newDate.getDate();
            let monthIndex = newDate.getMonth();
            let year = newDate.getFullYear();

            return (
              dayNames[dayName] +
              " " +
              day +
              " " +
              monthNames[monthIndex] +
              " " +
              year
            );
          },
          formatShortDate : function (datein) {
            let date = new Date(datein);
            let newDate = new Date(
              date.getTime() + date.getTimezoneOffset() * 60 * 1000
            );

            return (
               this.$moment(newDate).format('D/MM/YY')
            );
          }
    }

}
